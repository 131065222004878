export default {
  "nav": {
    "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرئيسية"])},
    "cats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاقسام"])},
    "favs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المفضلة"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تواصل معنا"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ابحث هنا"])},
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ارفع اعلانك"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الدخول"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بياناتي"])},
    "rates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقييماتي"])},
    "ads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعلاناتي"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الخروج"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف الحساب"])}
  },
  "common": {
    "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المزيد"])},
    "uploadAds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة بنر إعلاني"])},
    "sendRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إرسال طلب"])},
    "uploadImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ارفع صورة"])},
    "finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متابعة"])},
    "sendSuc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم إرسال الطلب للإدارة سيتم الرد عليك"])}
  },
  "cats": {
    "browse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصفح الأقسام"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكل"])},
    "browswAds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصفح الإعلانات"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بحث"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فلتر حسب"])},
    "mainDep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القسم الرئيسي"])},
    "subDep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القسم الفرعي"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المدينة"])},
    "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النوع"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف"])},
    "deliveryCompans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شركات شحن وتوصيل"])}
  },
  "footer": {
    "mainLinks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الروابط الأساسية"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرئيسية"])},
    "cats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأقسام"])},
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ارفع اعلان"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تواصل معنا"])},
    "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشروط والاحكام"])},
    "followUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تابعنا عبر"])},
    "facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فيسبوك"])},
    "insta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انستجرام"])},
    "twitter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تويتر"])},
    "youtube": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يوتيوب"])},
    "snap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سناب شات"])},
    "nawaf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جميع الحقوق محفوظة لموقع نواف"])},
    "awamer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصميم وبرمجة مؤسسة أوامر الشبكة"])}
  },
  "contact": {
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إرسال"])},
    "messageText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نص الرسالة"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الإلكتروني"])},
    "fullName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم كاملا"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تواصل معنا"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العنوان"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الجوال"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ"])},
    "confirmPass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد كلمة المرور الجديدة"])},
    "enterConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل كلمة المرور الجديدة مرة أخرى"])},
    "newPass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور الجديدة"])},
    "enterNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل كلمة المرور الجديدة"])},
    "enterCurrent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل كلمة المرور الحالية"])},
    "currentPass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور الحالية"])},
    "changePassTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تغيير كلمة المرور"])}
  },
  "profile": {
    "changeInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل بياناتي"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المستخدم"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الالكتروني"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الهاتف"])},
    "saveChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ التعديلات"])},
    "changePass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تغيير كلمة المرور"])}
  },
  "validation": {
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم بملئ الحقل الفارغ"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صيغة البريد الإلكتروني خاطئة"])}
  },
  "auth": {
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الدخول"])},
    "phone_mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الجوال / البريد الالكتروني"])},
    "createAcc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء حساب"])},
    "donotHaveAcc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ليس لديك حساب؟"])},
    "forgetPass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نسيت كلمة المرور؟"])},
    "passwordPlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل كلمة المرور"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور"])},
    "phonePlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل رقم الجوال / البريد الالكتروني"])}
  }
}