<template>
  <section class="home-section">
    <div class="home-carousel">
      <div v-if="banners.length > 1">
        <swiper
          :pagination="pagination"
          :modules="modules"
          :navigation="true"
          class="mySwiper"
          :loop="true"
          :autoplay="{
            delay: 2500,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }"
        >
          <swiper-slide
            class="home-item"
            v-for="slider in banners"
            :key="slider.id"
          >
            <img class="home-img" :src="slider.image" alt="" />
            <div class="container">
              <div class="row">
                <div class="col-lg-6">
                  <div class="home-content">
                    <p class="home-desc">
                      {{ slider.details }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </swiper-slide>
          <a
            class="main-btn lg up mybtn"
            :data-bs-toggle="isToken ? 'modal' : ''"
            :data-bs-target="isToken ? '#imgModal' : ''"
            @click="preventLogin"
          >
            {{ baner_text }}
          </a>
        </swiper>
      </div>
      <div v-else-if="banners.length === 1">
        <div class="single-image">
          <img class="home-img" :src="banners[0].image" alt="" />
        </div>
        <a
          class="main-btn lg up mybtn"
          :data-bs-toggle="isToken ? 'modal' : ''"
          :data-bs-target="isToken ? '#imgModal' : ''"
          @click="preventLogin"
        >
          {{ baner_text }}
        </a>
      </div>
      <div v-else>
        <div class="single-image" style="border: 5px solid cadetblue;border-radius: 5px;width: 100%;height: 500px;">
          <!-- <img class="home-img" :src="banners[0].image" alt="" /> -->
        </div>
        <a
          class="main-btn lg up mybtn"
          :data-bs-toggle="isToken ? 'modal' : ''"
          :data-bs-target="isToken ? '#imgModal' : ''"
          @click="preventLogin"
        >
          {{ baner_text }}
        </a>
      </div>
    </div>
  </section>

  <addAdsPanner />
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
// Import Swiper styles
import "swiper/css";

import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";

// import add advertise panner
import addAdsPanner from "../ads/addAdsPanner.vue";
import axios from "axios";
export default {
  data() {
    return {
      baner_text: "",
      isToken:window.localStorage.getItem('token') ? true : false
    };
  },
  methods: {
    preventLogin() {
      if (
        !this.isToken
      ) {
        this.$swal({
          icon: "error",
          title: "قم بتسجيل الدخول اولا",
          timer: 3000,
          showConfirmButton: false,
        });
      } else {
        // document.querySelector(".mybtn").attributes[2].value = "#imgModal";
        // document.querySelector(".mybtn").attributes[1].value = "modal";
      }
    },
    async getHomeLogo() {
      await axios
        .get("home", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          this.baner_text = res.data.data.baner_text;
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
  props: {
    banners: Array,
  },
  mounted() {
    this.getHomeLogo();
  },
  components: {
    Swiper,
    SwiperSlide,
    addAdsPanner,
  },
  setup() {
    return {
      pagination: {
        clickable: true,
      },

      modules: [Autoplay, Pagination, Navigation],
    };
  },
};
</script>

<style>
a {
  text-decoration: none !important;
}
.main-btn.lg {
  transform: translate(-22%, -150%);
  position: relative;
  z-index: 10;
}
</style>
