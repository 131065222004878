<template>
  <loader v-if="loader" />
  <uploadAdForm />
  <addAdsPanner />
</template>

<script>
import uploadAdForm from "../components/ads/uploadAdsForm.vue";
import loader from "../components/Shared/pageLoader.vue";
import addAdsPanner from "../components/ads/addAdsPanner";
export default {
  data() {
    return {
      loader: true,
    };
  },
  components: {
    uploadAdForm,
    loader,
    addAdsPanner,
  },
  mounted() {
    this.loader = false;
  },
};
</script>

<style></style>
