<template>
    <adsPaymentForm />
</template>

<script>
import adsPaymentForm from '../components/ads/adsPaymentForm.vue'
export default {
    components : {
        adsPaymentForm
    }
}
</script>

<style>

</style>