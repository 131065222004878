<template>
  <section class="catogry-section">
    <div class="catogry-carousel owl-carousel">
      <div class="pb-4 pt-5">
        <swiper
          :pagination="pagination"
          :modules="modules"
          :breakpoints="{
            '800': {
              slidesPerView: 2,
              spaceBetween: 5,
            },
          }"
          :slides-per-view="1"
          :centeredSlides="true"
          :space-between="10"
          :navigation="images.length > 1"
          class="mySwiper"
          :autoplay="autoplay"
          :loop="images.length > 1"
        >
          <swiper-slide
            class="home-item"
            v-for="slider in images"
            :key="slider.id"
          >
            <img class="home-img" :src="slider.images" alt="" @click="handleShowImg(slider.images)"/>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </section>

  <!-- Popup for showing the clicked image -->
  <div v-if="showPopup" class="popup" @click="handleClosePopup">
    <div class="popup-content" @click.stop>
      <button class="close-btn" @click="handleClosePopup">✖</button>
      <img :src="popupImg" alt="img" loading="lazy" />
    </div>
  </div>
</template>


<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination, Navigation } from "swiper";
import { ref } from "vue";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    images: Array,
  },
  setup() {
    const pagination = {
      clickable: true,
    };
    const autoplay = {
      delay: 3500,
      disableOnInteraction: false,
    };
    const modules = [Pagination, Autoplay, Navigation];
    
    // to handle the shown image in the popup
    const popupImg = ref('');
    const showPopup = ref(false);

    const handleShowImg = (imgSrc) => {
      popupImg.value = imgSrc;
      showPopup.value = true;
    };

    const handleClosePopup = () => {
      showPopup.value = false;
    };

    return {
      pagination,
      autoplay,
      modules,
      popupImg,
      showPopup,
      handleShowImg,
      handleClosePopup,
    };
  },
};

</script>

<style>
a {
  text-decoration: none !important;
}
.catogry-section .swiper {
  overflow: visible;
}
.catogry-section .swiper-horizontal > .swiper-pagination-bullets,
.catogry-section .swiper-pagination-bullets.swiper-pagination-horizontal,
.catogry-section .swiper-pagination-custom,
.catogry-section .swiper-pagination-fraction {
  bottom: -32px;
}
.swiper-pagination-bullet {
  transition: all 0.3s ease-in-out;
}
.catogry-section .home-item .home-img {
  width: 100%;
  height: 280px;
  border-radius: 25px;
  object-fit: cover;
  cursor: pointer;
}
.catogry-section .swiper-slide {
  transform: scale(0.9);
  transition: all 0.4s ease-in-out;
}
.catogry-section .swiper-slide-prev {
  transform: scale(0.9);
  transition: all 0.4s ease-in-out;
}
.catogry-section .swiper-slide.swiper-slide-active {
  transform: scale(1) !important;
}
@media screen and (max-width: 900px) {
  .catogry-carousel {
    padding: 0px 8px;
  }
}

/* Popup styles */
.popup {
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1000;
}

.popup-content {
  position: relative;
  background: #fff;
  padding: 5px;
  border-radius: 10px;
  text-align: center;
}

.popup-content img {
  max-width: 100%;
  width: 100%;
  max-height: 80vh;
  border-radius: 10px;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  font-size: 14px;
  color: #333;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0 0 2px #e4e4e4;
}

</style>
