<template>
  <div class="modal-dialog" style="max-width: 1000px">
    <div class="modal-content">
      <div class="content-model-me">
        <div class="modal-header">
          <h2 class="section-title">تعديل بانر اعلاني</h2>
        </div>

        <form ref="uploadForm" @submit.prevent="uploadBanners">
          <div class="modal-body">
            <div class="mb-3">
              <label for="exampleFormControlTextarea1" class="form-label"
                >تفاصيل البانر <span style="color: gray">*اختياري*</span></label
              >
              <textarea
                class="form-control"
                name="details"
                v-model="details"
                id="exampleFormControlTextarea1"
                rows="3"
                placeholder="اكتب هنا"
              ></textarea>
              <div>بامكانك الكتابة على الصورة او عدم الكتابة</div>
            </div>

            <div class="upload-img my-4">
              <label for="imgUpload" class="w-100 mb-1 text-center"
                >اضف صورة البانر</label
              >
              <input
                type="file"
                name="image"
                accept="image/*"
                id="imgUpload"
                class="hidden-input img-upload-input"
                @change="uploadPanner"
              />
              <label for="imgUpload" class="label-img lg mx-auto">
                <img
                  :src="image"
                  alt=""
                  class="w-100 h-100"
                  style="max-width: 100% !important"
                />
              </label>
              <div class="img-upload-show"></div>
            </div>
          </div>

          <div class="modal-footer gap-3">
            <button class="main-btn md up" :disabled="disabled">
              تعديل البانر
              <div class="spinner-border" role="status" v-if="disabled">
                <span class="visually-hidden">Loading...</span>
              </div>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>

  <!-- done modal  -->

  <v-dialog v-model="dialog" width="auto" persistent>
    <v-card>
      <div class="d-flex justify-content-end w-100">
        <button
          type="button"
          class="close-model-btn"
          style="font-size: 22px"
          @click="dialog = false"
        >
          <i class="fa-regular fa-circle-xmark"></i>
        </button>
      </div>

      <div class="modal-header">
        <h2 class="section-title">{{ $t("common.sendSuc") }}</h2>
      </div>

      <div class="modal-body">
        <img :src="done" alt="" class="done-img" />
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      image: `${this.$route.query.image}`,
      done: require("@/assets/imgs/done.gif"),
      logOutImg: require("@/assets/imgs/alert.gif"),
      widthAll: false,
      details: `${this.$route.query.details || ""}`,
      adPanner: false,
      dialog: false,
      disabled: false,
    };
  },
  mounted() {
    this.adPanner = true;
  },
  methods: {
    uploadPanner(e) {
      const file = e.target.files[0];
      this.image = URL.createObjectURL(file);

      console.log(this.image);
      console.log(file);

      this.widthAll = true;
    },

    async uploadBanners() {
      const fd = new FormData(this.$refs.uploadForm);
      await axios
        .post(`update-banner/${this.$route.params.id}`, fd, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          if (res.data.key == "success") {
            this.adPanner = false;
            this.dialog = true;
            setTimeout(() => this.$router.push("/bannerView"), 2000);
          } else {
            this.$swal({
              icon: "error",
              title: res.data.msg,
              timer: 2000,
              showConfirmButton: false,
            });
          }
          this.disabled = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style></style>
