<template>
  <successAdComponent />
</template>

<script>
import successAdComponent from "../components/ads/successAdComponent.vue";
export default {
  components: {
    successAdComponent,
  },
};
</script>

<style></style>
